body {
  margin: 0;
  font-family: 'Rubik', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #00344B;
}

code {
  font-family: 'Rubik', monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
